import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Navbar from "./navbar";
import Home from "../home"
import '../../assets/css/components/common.css'

function App()
{
    const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return (
            <div class="splashscreen">
                <h1>Loading...</h1>
            </div> 
        );
    }

    if (!isAuthenticated)
    {
        loginWithRedirect();
    }
    else
    {
        return (
            <>
                <Navbar/>
                <Home/>
            </>
        );
    }
};

export default App;