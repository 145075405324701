import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './components/common/App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider domain='dev-3fbe8fxoxm1qa8lx.us.auth0.com' clientId='nLUAB0vDtw3wnW6NF63AbeQ9JURtPlAL' authorizationParams={{redirect_uri: window.location.origin}}>
    <React.StrictMode>
      <App/>
    </React.StrictMode>
  </Auth0Provider>
);