import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import '../assets/css/components/common.css';
import '../assets/css/components/home.css';
import 'boxicons/css/boxicons.css';

function Home()
{
    const { user } = useAuth0();
    return (
        <div class="content">
            <div class="card title-card">
                <h1 class="text">Hello, {user.name}.</h1>
                <a href="#sidebar" class="title-card nolink"><i class='bx bx-bell text notif-icon'></i></a>
            </div>
            <div class="card news-card">
                <h2 class="text">Updates</h2>
                <p class="updates-box text text-wrap">The SSO is undergoing major redesigns and many features are subject to change. Please report any bugs you encounter directly to <a href="https://github.com/ALEXWARELLC" class="text-link">ALEXWARELLC</a>. Thank you.</p>
            </div>
            <div class="card news-card">
                <h2 class="text">Latest Commit: <a class="nolink text-link" href="https://github.com/Altex-Studios/MyBallsItch">Error</a>.</h2>
            </div>
        </div>
    );
};

export default Home;