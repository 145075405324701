import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import 'boxicons/css/boxicons.css'
import '../../assets/css/components/navbar.css'
import DarkModeToggle from '../../assets/js/navbar';


const Navbar = () =>
{
    const { user, logout } = useAuth0();
    return (
        <nav class="sidebar">
            <header>
                <div class="image-text">
                    <span class="image">
                        <img src={user.picture} alt="User Icon"/>
                    </span>
                    <div className="text header-text">
                        <span className="name">{user.name}</span>
                        <span className="profession">{user.email}</span>
                    </div>
                </div>

                {/* <i class="bx bx-chevron-right toggle"></i> */}

            </header>

            <div class="menu-bar">
                <div class="menu">
                    <ul class="menu-links">
                        <li class="nav-link"><a href="home"><i class="bx bx-home-alt icon"></i><span class="text nav-text">Home</span></a></li>
                        {/* <li class="nav-link"><a href="#"><i class="bx bx-bar-chart-alt-2 icon"></i><span class="text nav-text">Analytics</span></a></li>
                        <li class="nav-link"><a href="#"><i class="bx bx-cog icon"></i><span class="text nav-text">AutoConfigs</span></a></li>
                        <li class="nav-link"><a href="#"><i class="bx bx-link icon"></i><span class="text nav-text">Links</span></a></li> */}
                        <li class="nav-link"><a href="profile"><i class="bx bx-user icon"></i><span class="text nav-text">Profile</span></a></li>
                    </ul>
                </div>
                <div class="bottom-content">
                    <li class="noselect"><a onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} href='#sidebar'><i class="bx bx-log-out icon"></i><span class="text nav-text">Logout</span></a></li>
                    <li class="mode"><div class="moon-sun"><i class="bx bx-moon icon moon"></i><i class="bx bx-sun icon sun"></i></div><span class="mode-text text">Dark Mode</span><div class="toggle-switch" onClick={DarkModeToggle}><span class="switch"></span></div></li>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;